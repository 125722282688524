






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.bottomdiv{
    background:#e8eaec;
    height:40px;
    line-height:40px;
    margin-top:1px ;
}
.main_back{
    height: 100vh;
    margin:0px auto;  
    width:1230px;
    border:1px solid #fff;
    background: #f8f8f9;
     
}
.paybtn:hover{
    background:#ac7c34;
}
.paybtn{
    height:40px;
    background:#f87a7a;
    width:120px;
    color:white;
    font-size:18px;
}
.item-width  {
    width: 32%;
}
.item-width2  {
    width: 48%;
}
.row-width  {
    width: 97%;
}
.pub_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
    .el-dialog {
        margin: 0 auto !important;
        height: 90%;
        overflow: hidden;
        .el-dialog__body {
            position: absolute;
            left: 0;
            top: 54px;
            bottom: 0;
            right: 0;
            padding: 0;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}
